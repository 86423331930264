export async function sendTextAjax(url, data, method) {
    let csrf = $('meta[name="csrf-token"]').attr('content');
    let sendData;
    let headers = new Headers({
        "X-CSRF-Token": csrf,
        "Accept": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        ...(method === 'POST' || method === 'PUT' || method === 'DELETE' ? {"Content-Type": "application/json"} : {})
    });

    if (data) {
        sendData = JSON.stringify({
            ...data,
            _token: csrf
        });
    } else {
        sendData = JSON.stringify({ _token: csrf });
    }

    let requestOptions = {
        headers: headers,
        method: method,
        redirect: 'follow',
        ...(method === 'POST' || method === 'PUT' ? {
            body: sendData
        } : {})
    };

    try {
        const response = await fetch(url, requestOptions);
        return response.text();
    } catch (err) {
        console.error('Fetch error:', err);
    }
}
