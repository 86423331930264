import { sendJSONAjax } from './ajax-requests/sendJSONAjax';
import { sendTextAjax } from './ajax-requests/sendTextAjax';

let modalsBtns = document.querySelectorAll('.js-open-modal')

if (modalsBtns !== null && modalsBtns !== undefined) {
    modalsBtns.forEach((btn) => {
        btn.addEventListener('click', function (e) {
            e.preventDefault()
            let neededModalId = this.getAttribute('data-href')

            let neededModal = document.getElementById(neededModalId)
            if (neededModal !== null && neededModal !== undefined) {
                neededModal.style.display = "flex";
            }
        })
    })
}


let modalCloseBtns = document.querySelectorAll('.js-modal-close-modal')
if (modalCloseBtns !== null && modalCloseBtns !== undefined) {
    modalCloseBtns.forEach((btn) => {
        btn.addEventListener('click', function (e) {
            e.preventDefault()

            let neededModal = this.closest('.modal-item')
            if (neededModal !== null && neededModal !== undefined) {
                neededModal.style.display = "none";
            }
        })
    })
}


let calendlyBtns = document.querySelectorAll('.js-calendly-open')
if (calendlyBtns !== null && calendlyBtns !== undefined) {
    calendlyBtns.forEach((btn) => {
        btn.addEventListener('click', function (e) {
            e.preventDefault()

            if (Calendly !== null && Calendly !== undefined) {
                let popupBackgroundColor = 'f2f2f2'
                let popupHideGDPRBanner = '1'
                let popupTextColor = '000000'
                let popupPrimaryColor = '3dbbff'
                let userId = '';
                let userEmail = '';
                let argsA1 = '';
                let name = '';
                let utm_source = '';

                if (vars !== undefined && vars !== null) {
                    userId = vars.id.trim();
                    userEmail = vars.email.trim();
                    argsA1 = vars.a1.trim();
                    name = vars.name.trim();
                    utm_source = vars.utm_source.trim();
                }

                let urlLink = this.getAttribute('href')

                let urlLinkParams = '?&hide_gdpr_banner=' + popupHideGDPRBanner + '&background_color=' + popupBackgroundColor + '&text_color=' + popupTextColor + '&primary_color=' + popupPrimaryColor + '&id=' + userId + '&email=' + userEmail + '&name=' + name + '&a1=' + argsA1 + '&utm_source=' + utm_source

                let full_link = urlLink + urlLinkParams

                Calendly.initPopupWidget({url: full_link});

            }
            return false;
        })
    })
}

let forgotPassForm = document.getElementById('forgotPassForm')
if (forgotPassForm !== null && forgotPassForm !== undefined) {
    forgotPassForm.addEventListener('submit', function (e) {
        e.preventDefault()

        let url = this.getAttribute('action')
        let formData = new FormData(this)

        let invalidFeedbackContainer = this.querySelector('.invalid-feedback')
        invalidFeedbackContainer.style.display = 'none'

        const modal1 = document.getElementById("modal1");
        const modal2 = document.getElementById("modal2");

        let button = this.querySelector('button[type=submit]')
        let buttonTextDefault = button.getAttribute('data-text-default')
        let buttonTextProcessing = button.getAttribute('data-text-processing')
        let buttonTextSuccess = button.getAttribute('data-text-success')
        let buttonTextError = button.getAttribute('data-text-error')

        let requestOptions = {
            method: 'POST',
            body: formData,
            headers: new Headers({'accept': 'application/json'}),
            redirect: 'follow'
        };

        button.textContent = buttonTextProcessing;
        button.setAttribute('disabled', true)

        fetch(url, requestOptions)
            .then(response => response.text())
            .then((result) => {
                let resp = JSON.parse(result)
                if (resp.status !== undefined && resp.status !== null && resp.status === true) {

                    button.textContent = buttonTextSuccess;

                    if (modal1 !== null) {
                        modal1.style.display = "none";
                    }
                    if (modal2 !== null) {
                        modal2.style.display = "flex";
                    }

                    setTimeout(function () {
                        button.textContent = buttonTextDefault;
                        button.removeAttribute('disabled')
                    }, 3000)

                } else {
                    invalidFeedbackContainer.innerHTML = resp.message
                    invalidFeedbackContainer.style.display = 'block'

                    button.textContent = buttonTextError;

                    setTimeout(function () {
                        button.textContent = buttonTextDefault;
                        button.removeAttribute('disabled')
                    }, 2000)
                }
            })
            .catch((errors) => {
                let resp = JSON.parse(errors)
            });
    })
}

let sendItAgainBtn = document.querySelector('.js-send-it-again')

if (sendItAgainBtn !== null && sendItAgainBtn !== undefined) {
    sendItAgainBtn.addEventListener('click', function (e) {
        e.preventDefault()

        const modal1 = document.getElementById("modal1");
        const modal2 = document.getElementById("modal2");

        if (modal2 !== null) {
            modal2.style.display = "none";
        }

        if (modal1 !== null) {
            modal1.style.display = "flex";
        }
    })
}

let disabledReportBtns = document.querySelectorAll('.report-btn-disabled')
if (disabledReportBtns !== null && disabledReportBtns !== undefined) {
    disabledReportBtns.forEach((disabledReportBtn) => {
        disabledReportBtn.addEventListener('click', function (e) {
            e.preventDefault()
        })
    })
}


async function sendAjax(url, data) {
    let csrf = $('meta[name="csrf-token"]').attr('content')

    let sendData = new FormData();

    if (data !== null && data !== undefined) {
        for (const [key, value] of Object.entries(data)) {
            sendData.append(key, value);
        }
    }
    sendData.append('_token', csrf);

    const headers = new Headers({
        "X-CSRF-Token": csrf,
        "Accept": "application/json",
        "X-Requested-With": "XMLHttpRequest"
    });

    let requestOptions = {
        headers: headers,
        method: 'POST',
        body: sendData,
        redirect: 'follow'
    };
    const response = await fetch(url, requestOptions).then(response => {
        if (response.redirected) window.location.href = response.url;
    }).catch(function(err) {
        console.info(err);
    });
    const res = await response
    return res.json();
}

function responseAction(response) {

    let templates = response.templates

    let topicsCardsList = $(document).find('.js-topics-cards')
    if (topicsCardsList.length !== 0) {
        if (response.loadMore === true) {
            $(document).find('.js-topics-cards-items').append(templates.cards)

            if (response.currentPage === response.maxPages) {
                $(topicsCardsList).find('.load-block').hide()
            }
        } else {
            $(topicsCardsList).replaceWith(templates.cards)
        }
    }

    let topicsLinksList = $(document).find('.js-topics-links')
    if (topicsLinksList.length !== 0) {
        if (response.loadMore === true) {
            $(document).find('.js-topics-links-items').append(templates.links)

            if (response.currentPage === response.maxPages) {
                $(topicsLinksList).find('.load-block').hide()
            }
        } else {
            $(topicsLinksList).replaceWith(templates.links)
        }
    }

    $('.js-topics-container').attr('data-current-page', response.currentPage)
    $('.js-topics-container').attr('data-max-pages', response.maxPages)
}


$(document).on('click', '.btn-favourites', function (e){
    e.preventDefault()
    let thisBtn = $(this)

    let topicId = $(thisBtn).attr('data-topic-id')
    let allThisTopicCardBtns = $('.btn-favourites[data-topic-id=' + topicId + ']')

    let sendObj = {
        topic_id: topicId,
    }

    if($(thisBtn).hasClass('btn-favourites--active')){
        sendObj.adding = 0
    } else{
        sendObj.adding = 1
    }

    console.log(sendObj)

    let url = '/topic-bookmark'

    sendAjax(url, sendObj).then(response => {
        $(allThisTopicCardBtns).toggleClass('btn-favourites--active')
    })
});


const checkboxes = document.querySelectorAll('.js-milestones-checkbox');

if (checkboxes !== undefined || checkboxes) {
    checkboxes.forEach((checkbox) => {
        checkbox.addEventListener('change', function() {
            const form = checkbox.closest('.js-milestones-form');
            const url = form.getAttribute('action');

            sendAjax(url, form).then(response => {
                console.log('-----response-----',response)
            });
        });
    });
}

const taskListBtn = document.querySelectorAll('.js-task-item-btn');

if (taskListBtn !== null && taskListBtn !== undefined) {
    taskListBtn.forEach((item) => {
        item.addEventListener('click', () => {
            const taskItem = item.closest('.js-task-item');
            const milestoneItemId = item.closest('[data-milestone-id]').dataset.milestoneId;
            const taskItemId = taskItem.getAttribute('id');
            const sendObj = {
                workstream_id: taskItemId
            }

            const url = `/milestones/templates/${milestoneItemId}/milestone`;

            sendAjax(url, sendObj);
        });
    })
}

const btnToggleCompletion = document.querySelector('.js-toggle-completion');

if (btnToggleCompletion) {
    btnToggleCompletion.addEventListener('click', () => {
        const url = btnToggleCompletion.getAttribute('data-url');

        sendJSONAjax(url,  null, 'POST').then(response => {
            if (response.errors) {
                btnToggleCompletion.classList.add('btn--gray');
                btnToggleCompletion.classList.remove('btn--blue');
            } else {
                if (response.data.milestone.is_completed) {
                    btnToggleCompletion.classList.remove('btn--gray');
                    btnToggleCompletion.classList.add('btn--blue');
                } else {
                    btnToggleCompletion.classList.add('btn--gray');
                    btnToggleCompletion.classList.remove('btn--blue');
                }
            }
        });
    });
}

const btnActionCompleted = document.querySelectorAll('.js-action-completed');

btnActionCompleted.forEach((btn) => {
    if (btn) {
        btn.addEventListener('click', () => {
            // const taskItem = btn.closest('.js-task-item');
            // const milestoneItemId = btn.closest('[data-milestone-id]').dataset.milestoneId;
            const img = btn.querySelector('img');
            const imgSrc = img.getAttribute('data-src');
            const slug = '';
            const sendObj = {
                workstream_id: 'taskItemId'
            }

            const url = introForm.getAttribute('action');

            sendAjax(url).then(response => {
                if (response) {
                    btn.classList.remove('btn--gray');
                    btn.classList.add('btn--blue');
                    btn.setAttribute('disabled', true);
                    img.src = imgSrc;
                }
            });
        });
    }
});

// remove milestone
const btnSettingsRemove = document.querySelector('.js-settings-remove');
const btnRemoveMilestone = document.querySelector('.js-remove-milestone');

if (btnRemoveMilestone) {
    btnSettingsRemove.addEventListener('click', () => {
        const url = btnSettingsRemove.getAttribute('data-delete-url');
        btnRemoveMilestone.setAttribute('data-url', url);
    });
    btnRemoveMilestone.addEventListener('click', () => {
        const url = btnRemoveMilestone.getAttribute('data-url');

        sendJSONAjax(url,  null, 'DELETE');
    });
}


// create new action
const createActionForm = document.querySelector('.js-create-action-form');

if (createActionForm) {
    createActionForm.addEventListener('submit', (e) => {
        e.preventDefault();
        const url = createActionForm.getAttribute('action');
        const milestoneAllDataUrl = createActionForm.getAttribute('data-url-milestone');
        const formData = new FormData(createActionForm);
        const milestoneTime = document.querySelector('.js-milestone-time');
        const actions = document.querySelector('.js-milestone-actions span');
        const percentsEl = document.querySelector('.js-percents');

        const actionData = {};
        formData.forEach((value, key) => {
            if (key === 'time_commitment_minutes' || key === 'time_commitment_hours') {
                actionData[key] = parseInt(value, 10);
            } else {
                actionData[key] = value;
            }
        });

        sendTextAjax(url,  actionData, 'POST').then(response => {
            const targetBlock = document.querySelector('.js-create-action');
            const asideActionTab = document.querySelector('.js-action-tab');
            targetBlock.insertAdjacentHTML('beforebegin', response);
            createActionForm.reset();
            sendJSONAjax(milestoneAllDataUrl, null, 'GET').then(milestoneDataRes => {
                if (milestoneDataRes) {
                    const asideListItem = `<li data-tab-target="${milestoneDataRes.data.milestone.slug}" class="tab">
                        <img width="20" height="20" class="tab__img" src="/frontend/img/icons/report.svg" alt="report" />
                        ${milestoneDataRes.data.milestone.name}
                    </li>`
                    milestoneTime.textContent = milestoneDataRes.data.milestone.all_actions_sum_time_commitment;
                    actions.textContent = milestoneDataRes.data.milestone.all_actions_count;
                    percentsEl.textContent = milestoneDataRes.data.milestone.all_actions_completed_percent;
                    asideActionTab.insertAdjacentHTML('beforebegin', asideListItem);
                }
            });
        });
    });
}

// toggle complete action
const btnActionComplete = document.querySelectorAll('.js-action-completed-btn');
if (btnActionComplete.length) {
    document.addEventListener('click', (e) => {
        if (e.target && e.target.matches('.js-action-completed-btn')) {
            const btn = e.target.closest('.js-action-completed-btn');

            if (!btn) return;

            const url = btn.getAttribute('data-url');
            const btnText = btn.querySelector('span');
            const completedText = btn.getAttribute('data-completed-text');
            const completeText = btn.getAttribute('data-complete-text');
            const btnImg = btn.querySelector('img');
            const imgSrcFill = btnImg.getAttribute('data-src-fill');
            const imgSrcEmpty = btnImg.getAttribute('data-src-empty');
            const parentId = btn.closest('.js-action-order').getAttribute('id');
            const asideTabItem = document.querySelector(`[data-tab-target=${parentId}]`);
            const asideTabItemImgSrcFill = asideTabItem.getAttribute('data-img-fill');
            const asideTabItemImgSrcEmpty = asideTabItem.getAttribute('data-img-empty');
            const asideTabItemImg = asideTabItem.querySelector('.tab__img');

            sendJSONAjax(url,  null, 'POST').then(response => {
                if (response.data.action.is_completed === true) {
                    btn.classList.remove('btn--gray');
                    btn.classList.add('btn--blue');
                    btnImg.src = imgSrcFill;
                    btnText.textContent = completedText;
                    asideTabItemImg.src = asideTabItemImgSrcFill;
                } else {
                    btn.classList.remove('btn--blue');
                    btn.classList.add('btn--gray');
                    btnImg.src = imgSrcEmpty;
                    btnText.textContent = completeText;
                    asideTabItemImg.src = asideTabItemImgSrcEmpty;
                }
            });
        }
    })
}

// edit action
document.addEventListener('click', (e) => {
   if (e.target && e.target.matches('.js-edit-action')) {
       const btn = e.target.closest('.js-edit-action');

       if (!btn) return;

       const parent = btn.closest('.js-action-order');
       const actionForm = parent.querySelector('.js-action-form');
       const editForm = parent.querySelector('.js-edit-action-form');
       actionForm.classList.add('hidden');
       editForm.classList.remove('hidden');
   }
});

// close action editing
document.addEventListener('click', (e) => {
    if (e.target && e.target.matches('.js-close-editing-action')) {
        const btn = e.target.closest('.js-close-editing-action');

        if (!btn) return;

        const parent = btn.closest('.js-action-order');
        const actionForm = parent.querySelector('.js-action-form');
        const editForm = parent.querySelector('.js-edit-action-form');
        actionForm.classList.remove('hidden');
        editForm.classList.add('hidden');
    }
});
